/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets

 Dark Blue: #6987c9
 Light Blue: #6bbaec
 Navy Grey: #BCC4DB
 Body Font: Lato

 RAG:
 - rgba(255,0,0,0.7);
 - rgba(255,165,0,0.7);
 - rgba(0,128,0,0.7);

 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url('https://unpkg.com/bootstrap@3.3.7/dist/css/bootstrap.min.css');

@import "../node_modules/angular-calendar/css/angular-calendar.css";

ion-header .back {
    position: absolute;
    right: 0;
    height: 100%;
    top: 0;
    display: grid;
    align-items: center;
    padding-right: 30px;
    cursor: pointer;
    background: #6987c9;
    padding-left: 25px;
    color: white;
    font-weight: bold;
}
ion-button {
    width: calc(100% - 20px);
    border-radius: 5px;
    height: 70px;
    font-weight: bold;
    font-size: 20px;
    float: left;
    margin-left: -10px;
    --background: #6987c9;
}
ion-button.red {
    --background: #a94442;
}
ion-button.green {
    --background: #42A948;
}
ion-button.create {
    min-width: 100px;
    max-width: 200px;
    font-size: unset;
    height: 35px;
    padding: 0;
    float: right;
    margin-right: 15px;
}
.box-shadow {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
body, span, p {
    color: #373D3F;
}

ion-searchbar {
    padding: 20px 15px 0 !important;
    text-align: center;
}
ion-searchbar.bottom {
    margin-bottom: 20px !important;
}
ion-searchbar {
    .searchbar-input-container {
        input{
            border-radius: 5px !important;
            padding: 15px !important;
            margin: 5px !important;
            width: calc(100% - 10px) !important;
            box-shadow: rgba(99, 99, 99, 0.5) 0px 1px 4px 0px !important;
        }
    }
}
.searchbar-search-icon.sc-ion-searchbar-md { 
    display:none; 
    width:0; 
    height:0; 
}

.table {
    padding: 20px 20px 10px;    
    margin-bottom: 0;
    text-align: center;
}
.table .inner {
    box-shadow: rgba(99, 99, 99, 0.5) 0 0 4px 0px;
    border-radius: 5px;
    padding: 10px 0 20px;
}
.table .inner .header {
    border-bottom: 1px solid #6987c9;
    font-weight: bold;
    padding-bottom: 5px;
    padding: 10px 0;
}
.table .inner .data {
    cursor: pointer;
    padding: 10px 0;
    align-items: center;
}
.table .inner .data.favourite {
    background: #6987c9 !important;
    color: white;
}
.table .inner .data:nth-child(odd) {
    background: #D3D3D3;
}
.table img {
    max-width: 100px;
    max-height: 75px;
}
.gauge {
    height: 300px;
    margin-bottom: -50px;
}
.chart {
    height: 400px;
}
ion-footer {
    right: 0;
    bottom: 0;
    position: initial;
    height: 50px;
    text-align: center;
    background: white;
    border-top: 1px solid var(--ion-color-step-150, #d7d8da);
}
ion-footer img {
    height: 100%;
}
ion-menu {
    max-width: 250px;
}
img.user-profile,
img.profile {
    border-radius: 100%;
}
img.profile {
    max-height: 75px;
    padding: 10px;
}
.upload {
    height: 100px;
    width: 100px;
    text-align: center;
    display: grid;
    align-items: center;
    cursor: pointer;
}
.upload ion-input {
    height: 100px;
    width: 100px;
    position: absolute;
    margin-top: -20px;
    margin-left: -20px;
    opacity: 0;
}
.text-center {
    text-align: center;
}
.block {
    display: block;
}

.kanban {
    padding: 5px 20px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin-top: 20px;
}
.kanban .k-head {
    padding: 25px 15px 15px 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
    font-weight: bold
}
.kanban .k-head .count {
    float: right;
    background: #6987c9;
    padding: 0 5px 1px 4px;
    border-radius: 5px;
    color: white;
}
.kanban .k-head .total {
    float: right;
    padding: 0 5px 1px 4px;
    border-radius: 5px;
    color: #6987c9;
}
.kanban .bucket {
    min-width: 400px;
    height: 100%;
    background: white;
    border-radius: 5px;
    padding: 0 0 10px;
    margin-right: 10px;
    box-shadow: 0px 0px 3px 0px grey;
    min-height: calc(100vh - 230px);
}
.kanban .cards {
    max-height: calc(100vh - 330px);
    overflow: auto;
}
.kanban .card {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    box-shadow: 0px 1px 5px 0px #ccc;
    cursor: pointer;
}
.kanban .card ion-row ion-col {
    padding-bottom: 0;
}
.kanban .favourite .card {
    border: 1px solid #6987c9;
    box-shadow: 0px 1px 5px 0px #6987c9;
}
ion-header .switch {
    position: absolute;
    right: 85px;
    height: 100%;
    top: 0;
    padding-top: 18px;
    cursor: pointer;
    width: 100px;
    text-align: center;
    border-left: 1px solid #ccc;
    font-weight: bold;
}
.delete-img {
    top: 0;
    margin-left: -20px;
    position: absolute;
    background: white;
    border-radius: 50%;
    color: red;
    cursor: pointer;
}

/* Scheduler */
.cal-all-day-events {
    min-height: 100px;
    position: fixed !important;
    width: 100%;
    z-index: 3;
    background: white;
    top: 55px;
} 
.cal-time-events {
    margin-top: 120px;
}
.cal-week-view .cal-all-day-events {
    margin-top: 65px;
    border-top: 1px solid #ccc;
    padding-top: 5px;
    overflow: scroll;
    max-height: 100px;
    padding-left: 60px;
    overflow-x: hidden;
}
.cal-week-view .cal-all-day-events .cal-event {
    margin-right: 10px;
    margin-left: -60px;
    border-radius: 5px;
}
.cal-event {
    cursor: pointer;
}
.cal-event-title {
    color: white;
}
.cal-week-view mwl-calendar-week-view-hour-segment, 
.cal-week-view .cal-hour-segment {
    // height: 50px !important;
}
.cal-week-view .cal-time-events .cal-event-container {
    /*min-height: 50px !important;*/
}
.cal-week-view .cal-time-events .cal-day-column {
    margin-top: 60px !important;
}

mwl-calendar-week-view-header {
    position: absolute;
    margin-top: 140px;
}
.yb-week .cal-time-events {
    margin-top: 95px;
}
.cal-week-view .cal-day-headers {
    /* width: 100vw; */
    width: calc(100vw - 285px);
    margin-top: -36px;
}
.current-time-marker,
.cal-current-time-marker {
    // display: none;
}
.cal-month-view {
    margin-top: 50px;
}

.create.export {
    --background: grey;
}